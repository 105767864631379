"use strict";
import { isComparePage } from "./page";

export function createIncrementObject(startNumber) {
  return new (class {
    constructor(startNumber) {
      this.number = startNumber;
    }
    getCount() {
      return this.number;
    }
    addCount(number) {
      this.number += number;
    }
  })(startNumber);
}

export function addClassesToElements({ targets, syntax }) {
  addRemoveClassesToElements({ targets, syntax, fncType: "add" });
}
export function removeClassesFromElements({ targets, syntax }) {
  addRemoveClassesToElements({ targets, syntax, fncType: "remove" });
}
function addRemoveClassesToElements({ targets, syntax, fncType }) {
  if (!targets) {
    return;
  }
  if (!syntax) {
    return;
  }
  if (targets.length === 0) {
    return;
  }
  if (targets.length) {
    // 0はfalseになる
    targets.forEach((target) => addRemoveClasses(target));
  } else {
    addRemoveClasses(targets);
  }

  function addRemoveClasses(target) {
    const classes = splitBySomething(syntax, " ");
    if (Array.isArray(classes)) {
      classes.forEach((word) => addRemoveClass(target, word, fncType));
      return;
    }
    addRemoveClass(target, classes, fncType);

    function splitBySomething(syntax, word) {
      if (syntax.includes(word)) {
        return syntax.split(word);
      }
      return syntax;
    }
    function addRemoveClass(target, word, fncType) {
      if (!word) {
        return;
      }
      target.classList[fncType](word);
    }
  }
}

export function getDispTypeByWGTT() {
  return getDispType("dispType");
}
export function getDispTypeLastByWGTT() {
  return getDispType("dispTypeLast");
}
function getDispType(key) {
  const target = document.getElementById("wrpDispTabTable");
  return target.dataset[key];
}

export function getStyleValueByNumber({ target, id, query, item }) {
  // element.styleはjsのelement.styleで設定した値しか取得できない
  // getComputedStyleはcss/styleタグで設定された値を取得できる
  // getBoundingClientRectは実際に表示されている要素の四角形の基本的な情報を取得できる>width,height,top,leftなど
  // > autoの場合
  //   element.style.width === ""
  //   getComputedStyle(element).width === "auto"
  //   getBoundingClientRect.width === 240

  // get target
  const t = (() => {
    if (target) return target;
    if (id) return document.getElementById(id);
    if (query) return document.querySelector(query);
  })();

  if (!t) return null;

  // get value
  const val = (() => {
    const getValue = (v) => {
      const v0 = v.replace("px", "");
      if (!v0) return null;
      const v1 = Number(v0);
      if (isNaN(v1)) return null;
      return v1;
    };
    // const v0 = Number(t.style[item].replace("px", ""));
    const v0 = getValue(t.style[item]);
    if (v0) return v0;
    // const v1 = Number(getComputedStyle(t)[item].replace("px", ""));
    const v1 = getValue(getComputedStyle(t)[item]);
    if (v1) return v1;
    return null;
  })();

  return val;
}

export function changeWindowByFlexOrBlock(dispType) {
  const wdtt = document.getElementById("wrpDispTabTable");
  const dispTypeOld = getDispTypeByWGTT();
  wdtt.dataset.dispType = dispType;
  if (dispTypeOld === "block" || dispTypeOld === "flex") {
    wdtt.dataset.dispTypeLast = dispTypeOld;
  } else if (!dispTypeOld) {
    wdtt.dataset.dispTypeLast = dispType;
  }

  const map = {
    wrapperSizeTable: { block: "block", flex: "block", full: "none" },
    // header: { block: "none", flex: "none", full: "none" },
    // footer: { block: "none", flex: "none", full: "none" },
    btnFullScreen: { block: "", flex: "", full: "none" },
    btnWindowed: { block: "none", flex: "none", full: "" },
    wrpDispTabTable: { block: "block", flex: "flex", full: "" },
    wrpDispAds: { block: "block", flex: "block", full: "flex" },
    wrapperBtnIcon: { block: "flex", flex: "flex", full: "grid" },
  };
  for (const key in map) {
    const target = document.getElementById(key);
    target.style.display = map[key][dispType];
  }

  if (!isComparePage()) return;
  const map2 = {
    block: { display: "", alignItems: "", justifyContent: "" },
    flex: { display: "", alignItems: "", justifyContent: "" },
    full: { display: "flex", alignItems: "center", justifyContent: "center" },
  };
  const page = document.getElementById("pageCompare");
  page.style.display = map2[dispType].display;
  page.style.alignItems = map2[dispType].alignItems;
  page.style.justifyContent = map2[dispType].justifyContent;
}

export function getScaleByDispType() {
  const scale = getScaleByWGTT();
  const dispType = getDispTypeByWGTT();
  const test = scale.byHeight < scale.byWidth ? scale.byHeight : scale.byWidth;
  const result = {
    // block: scale.byHeight,
    // flex: scale.byWidth,
    // full: scale.byHeight,
    block: test,
    flex: test,
    full: test,
  };
  return result[dispType];
}

export function getScaleByWGTT() {
  const wdtt = document.getElementById("wrpDispTabTable");
  const scaleByWidth = wdtt.dataset.scaleByWidth;
  const scaleByHeight = wdtt.dataset.scaleByHeight;
  return {
    byWidth: parseFloat(scaleByWidth),
    byHeight: parseFloat(scaleByHeight),
  };
}

export function getScaledValue(val) {
  const scale = getScaleByDispType();
  const scaledVal = val * scale;
  return Math.floor(scaledVal);
}

export function createDeepCopy(values) {
  const json = JSON.stringify(values);
  return JSON.parse(json);
}

export function headBigLetter(letter) {
  const firstLetter = letter.substring(0, 1).toUpperCase(); // 1文字目を大文字に変換する
  const otherLetter = letter.substring(1).toLowerCase(); // 1文字目以降を小文字に変換する
  return `${firstLetter}${otherLetter}`;
}

export function getEventTargetsByBetween(event) {
  if (!event) {
    return null;
  }
  const CLICK_EVENT_LOOP_MAX = 10;
  const t0 = event.currentTarget;
  const array1 = [event.target];
  const array2 = [event.target];
  for (let i = 0; i < CLICK_EVENT_LOOP_MAX; i++) {
    const t1 = array1.pop();
    if (t0 === t1) {
      break;
    }
    array1.push(t1.parentElement);
    array2.push(t1.parentElement);
  }
  return array2;
}

export function setClassBySelect(t, fncA, fncB) {
  t.classList[fncA]("text-emerald-500");
  t.classList[fncA]("font-semibold");
  t.classList[fncB]("hover:text-neutral-700");
}

export const setClassToIconByLight = (t) => {
  t.classList.add("grid");
  t.classList.add("justify-items-center");
  t.classList.add("content-center");
  t.classList.add("stroke-neutral-400");
  t.classList.add("hover:stroke-neutral-600");
  t.classList.add("hover:text-neutral-600");
};

// 行の高さも変わるからあまり使えない
export const getFontSizeByTarget = (length, target) => {
  const width = getStyleValueByNumber({ target, item: "width" });
  const height = getStyleValueByNumber({ target, item: "height" });
  const areaTarget = width * height;
  if (isNaN(areaTarget)) return null;
  // const areaFont = fontSize ** 2 * length;
  // const scale = areaTarget / areaFont;
  const areaFont = areaTarget / length;
  return Math.sqrt(areaFont);
};

export const getYears = (years) => {
  if (!years) return null;
  const years0 = years.split("-");
  if (years0[0] === years0[1]) {
    return years0[0];
  } else {
    return years;
    // return `${years0[0]} - ${years0[1]}`;
  }
};

export const getYearsByArray = (years) => {
  if (!isNaN(years)) return years;
  const yearAry = years.split(",");
  const ln = yearAry.length - 1;
  return `${yearAry[0]}-${yearAry[ln]}`;
};
