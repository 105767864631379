"use strict";
import {
  getStyleValueByNumber,
  getEventTargetsByBetween,
  setClassBySelect,
  getDispTypeByWGTT,
  getFontSizeByTarget,
  getYears,
  replaceName,
} from "./_common";
import { FONT_COLOR, NAMES, ZINDEX } from "./_const";
import { drawBikes, getDrawColor, initBikes, removeBikes } from "./bike";
import { compare, setDrawColorToCompareTag } from "./compare";
import {
  duplicateImgDisp,
  initBikeName,
  initImgDisp,
  removeImgDisp,
  setInfoToDisp,
} from "./display";
import { setStyleToTmbImgs } from "./img";
import {
  showFullImgBy,
  showFullImgByBike,
  showImgsByYears,
} from "./imgsByYear";
import { LOWER_MENU } from "./lowerMenu";
import { execGetBikeData, extractModelsByModelAll } from "./php";
import {
  changeSizePagination,
  createSizePagination,
  initSizePagination,
  removeSizePagination,
  setSizePagination,
} from "./sizePagination";
import {
  COL_COUNT,
  initCompareToSizeTable,
  initSizeTable,
  removeSizeTable,
  setBikeDataToSizeTable,
  setBikeNameToThNameHeader,
  setClassByHeader,
  setClassByHoverLight,
  setImgToSizeTable,
  setSizeTableBy,
  setStyleToTDTH,
  setWordingToThNameHeader,
} from "./sizeTable";
import { initSummaryValue } from "./values";
import { getWordingName, getWordingNameByAll } from "./wording";

const SM_LINE_WIDTH = 2;
const SMMM_WIDTH = 300; // SIDE_MENU_MAKER_MODEL_WIDTH
export const WRP_TMBS_GAP = 6;
export const WRP_TMBS_PADDING = 6;

export function setBikeDataToSideMenuMakerModel(values) {
  const models = extractModelsByModelAll(values, "mdl");
  const makers = extractModelsByModelAll(values, "mkr");
  makers.forEach((maker) => {
    const h = document.createElement("h3");
    h.dataset.class = "listMakerH";
    h.dataset.wordingId = `name${maker.mkr}`;
    setClassByHeader(h);
    h.classList.add("content-center");
    h.classList.add("h-10");
    h.classList.add("pl-2");
    h.style.fontSize = "26px";

    const list = document.createElement("li");
    list.dataset.class = "listMaker";
    list.dataset.mkr = maker.mkr;
    list.classList.add("mb-10");
    list.appendChild(h);
    const ul = document.createElement("ul");
    ul.classList.add("font-normal");
    const models2 = models.filter((model) => model.mkr === maker.mkr);
    for (let i = 0; i < models2.length; i++) {
      const model = models2[i];
      const remainder = i === 0 ? 0 : i % 2;
      const list = document.createElement("li");
      list.dataset.class = "listMakerModel";
      list.dataset.mkr = model.mkr;
      list.dataset.mdl = model.mdl;
      list.dataset.wordingId = `name${model.mdl}`;
      setClassByHoverLight(list);
      list.classList.add("leading-none");
      list.classList.add("content-center");
      list.classList.add("break-all");
      list.classList.add("pl-2");
      list.classList.add("pt-3");
      list.classList.add("pb-3");
      list.style.fontSize = "24px";
      if (remainder === 0) {
        // list.style.backgroundColor = "rgba(100,100,100,0.1)";
      } else {
        list.style.backgroundColor = "rgba(230,220,200,0.3)";
      }
      list.style.color = FONT_COLOR;
      ul.appendChild(list);
    }
    list.appendChild(ul);
    document.getElementById("smMakerModel").appendChild(list);
  });
}

export async function setBikeDataToSideMenuYear(values) {
  return new Promise((resolve) => {
    test(resolve);
  });

  async function test(resolve1) {
    const bikesByYear = extractModelsByModelAll(values, "year");
    for (let i = 0; i < bikesByYear.length; i++) {
      await createListByModel(bikesByYear[i]);
    }
    resolve1();

    async function createListByModel(bikeByYear) {
      return new Promise((resolve) => {
        test2(resolve);
      });

      async function test2(resolve2) {
        const list = document.createElement("li");
        list.dataset.class = "listYearModel";
        list.dataset.mkr = bikeByYear.mkr;
        list.dataset.mdl = bikeByYear.mdl;
        list.style.display = "none";

        const ul = document.createElement("ul");
        await createListByYears(bikeByYear, ul);
        list.appendChild(ul);
        document.getElementById("smYear").appendChild(list);
        resolve2();
      }

      async function createListByYears(bikeByMdl, ul) {
        return new Promise((resolve) => {
          test3(resolve);

          async function test3(resolve3) {
            const bikesByYear2 = bikesByYear.filter(
              (bikeByYear) =>
                bikeByYear.mkr === bikeByMdl.mkr &&
                bikeByYear.mdl === bikeByMdl.mdl
            );
            for (let j = 0; j < bikesByYear2.length; j++) {
              const bikeByYear = bikesByYear2[j];
              const btn = document.createElement("h3");
              btn.dataset.class = "yearH";
              btn.dataset.mkr = bikeByYear.mkr;
              btn.dataset.mdl = bikeByYear.mdl;
              btn.dataset.years = bikeByYear.year;
              btn.dataset.colors = bikeByYear.colors;
              btn.dataset.similar1 = "";
              btn.dataset.similar2 = "";
              setClassByHeader(btn);
              btn.classList.add("content-center");
              btn.classList.add("text-left");
              btn.classList.add("sticky");
              btn.classList.add("top-0");
              btn.classList.add("h-10");
              btn.classList.add("pl-2");

              // btn.classList.add("bg-white");
              btn.style.zIndex = 1;
              btn.type = "button";

              const p = document.createElement("p");
              p.dataset.class = "similarP";
              p.classList.add("text-lg");
              p.style.color = FONT_COLOR;

              const list = document.createElement("li");
              list.dataset.class = "listYears";
              list.classList.add("mb-24");
              list.style.fontSize = "24px";
              list.appendChild(btn);
              list.appendChild(p);
              ul.appendChild(list);
            }
            resolve3();
          }
        });
      }
    }
  }
}

export function setClassByWrpTmbs(t) {
  t.classList.add("flex");
  t.classList.add("flex-wrap");
  t.classList.add("w-full");
  t.classList.add("justify-items-start");
}

export function setStyleToSideMenu(setType) {
  const sm = document.getElementById("sideMenu");
  const smY = document.getElementById("smYear");
  const smMM = document.getElementById("smMakerModel");
  const smLine = document.getElementById("smLine");
  const similarPs = document.querySelectorAll(`p[data-class='similarP']`);
  const wrpTmbs = document.querySelectorAll(`div[data-class='wrpTmbs']`);

  const scale = getScaleBySideMenu();
  const dispType = getDispTypeByWGTT();

  const paddingSM = 8;
  const lmWidth = dispType === "full" ? LOWER_MENU.HEIGHT_BASE : 0;
  const lmHeight = dispType === "full" ? 0 : LOWER_MENU.HEIGHT_BASE;
  // *vh,vwだとスマホのブラウザで表示がずれる
  const cHeight = document.documentElement.clientHeight;
  const cWidth = document.documentElement.clientWidth;
  const smContentHeight = cHeight - paddingSM * 2 - lmHeight;
  smY.style.height = `${smContentHeight}px`;
  smMM.style.height = `${smContentHeight}px`;
  smLine.style.height = `${smContentHeight}px`;
  sm.style.padding = `${paddingSM}px`;

  document.getElementById("btnSmClose").style.display = "";
  document.getElementById("btnSmYearClose").style.display = "none";
  smMM.style.display = "";
  smY.style.display = "";

  if (dispType === "block") {
    smLine.style.display = "none";
    if (setType === "hideMakerModel") {
      smMM.style.display = "none";
      smY.style.width = `${cWidth - lmWidth - paddingSM * 2}px`;
    } else {
      smMM.style.width = `${cWidth - lmWidth - paddingSM * 2}px`;
      smY.style.display = "none";
    }
  } else {
    smLine.style.display = "";
    smLine.style.width = `${SM_LINE_WIDTH}px`;
    smLine.style.marginLeft = `${paddingSM / 2}px`;
    smLine.style.marginRight = `${paddingSM / 2}px`;
    smMM.style.width = `${
      SMMM_WIDTH - paddingSM * 2 - (paddingSM + SM_LINE_WIDTH)
    }px`;
    smY.style.width = `${cWidth - lmWidth - SMMM_WIDTH}px`;
  }

  const g = Math.floor(WRP_TMBS_GAP * scale);
  const p = Math.floor(WRP_TMBS_PADDING * scale);
  wrpTmbs.forEach((t) => {
    t.style.gap = `${g}px`;
    t.style.padding = `${p}px`;
  });

  similarPs.forEach((t) => (t.style.padding = `${p}px`));

  const smMMWidth = getStyleValueByNumber({ target: smMM, item: "width" });
  const smMMPadding = getStyleValueByNumber({ target: smMM, item: "padding" });
  const hs = document.querySelectorAll(`h3[data-class='listMakerH']`);
  const hWidth = smMMWidth - smMMPadding * 2;
  hs.forEach((t) => (t.style.width = `${hWidth}px`));

  const smYWidth = getStyleValueByNumber({ target: smY, item: "width" });
  const smYPadding = getStyleValueByNumber({ target: smY, item: "padding" });
  const btnWidth = smYWidth - smYPadding * 2;
  const yearHs = document.querySelectorAll(`h3[data-class='yearH']`);
  yearHs.forEach((t) => (t.style.width = `${btnWidth}px`));
}

export function getScaleBySideMenu() {
  // const dispType = getDispTypeByWGTT();
  // const lmWidth = dispType === "full" ? LOWER_MENU.HEIGHT_BASE * 1.3 : 0;
  // const tWidth = SMY_WIDTH + SMMM_WIDTH;
  const tWidth = 500 + SMMM_WIDTH;
  const cWidth = document.documentElement.clientWidth;
  return cWidth / tWidth;
}

export function showSideMenu(event) {
  document.getElementById("sideMenu").style.display = "flex";
  document.getElementById("btnNaviOpen").style.zIndex = "";
  document.getElementById("overlay").style.display = "";
  document.getElementById("btnShowSideMenu").style.zIndex = ZINDEX.OVERLAY + 1;
  setStyleToSideMenu();
  setStyleToTmbImgs();
}

export function execGetWidthBySideMenu() {
  const makerModelWidth = 250;
  const yearWidth = 500;
  const maxWidth = makerModelWidth + yearWidth;
  const width1 = document.documentElement.clientWidth;
  if (width1 > maxWidth) {
    // const scale = Math.floor(width1 / 2);
    return {
      makerModel: makerModelWidth,
      year: yearWidth,
      scale: 1,
    };
  } else {
    const scale = width1 / maxWidth;
    return {
      makerModel: makerModelWidth * scale,
      year: yearWidth * scale,
      scale,
    };
  }
}

export const onClickSmYearClose = (event) => {
  const t0 = getEventTargetsByBetween(event).find(
    (t) => t.id === "btnSmYearClose"
  );
  if (!t0) return;
  document.getElementById("smMakerModel").style.display = "";
  document.getElementById("smYear").style.display = "none";
  document.getElementById("btnSmYearClose").style.display = "none";
};

export const onBtnSmClose = (event) => {
  const t0 = getEventTargetsByBetween(event).find((t) => t.id === "btnSmClose");
  if (!t0) return;
  document.getElementById("smMakerModel").style.display = "none";
  document.getElementById("smYear").style.display = "none";
  document.getElementById("btnSmYearClose").style.display = "none";
  document.getElementById("btnSmClose").style.display = "none";
  document.getElementById("overlay").style.display = "none";
  document.getElementById("sideMenu").style.display = "none";
  document.getElementById("btnShowSideMenu").style.zIndex = "";
};

export async function onClickSideMenuMakerModel(event) {
  const listMakerModel = getEventTargetsByBetween(event).find(
    (t) => t.dataset.class === "listMakerModel"
  );
  if (!listMakerModel) return;

  const mkr = listMakerModel.dataset.mkr;
  const mdl = listMakerModel.dataset.mdl;
  const wrpTmbs = document.querySelector(
    `li[data-class='listYearModel'][data-mkr='${mkr}'][data-mdl='${mdl}'] div[data-class='wrpTmbs']`
  );

  const shield = document.getElementById("shield");
  const overlay = document.getElementById("overlay");
  const iconLoader = document.getElementById("iconLoader");

  overlay.style.display = "";

  // 画像がない場合
  if (!wrpTmbs) {
    shield.style.display = "";
    iconLoader.style.display = "";
    overlay.style.zIndex = ZINDEX.SHIELD - 1;
  }

  setInfoToDisp(mkr, "mkr");
  setInfoToDisp(mdl, "mdl");
  // initSizePagination();
  initModelUlToSmYear();
  if (!wrpTmbs) await showImgsByYears(mkr, mdl);
  setModelUlToSmYear(listMakerModel);
  // removeBikes();
  // removeImgDisp("header");
  // initSummaryValue();
  // initBikeName();
  // removeSizePagination();
  // removeSizeTable();
  setStyleToSideMenu("hideMakerModel");
  setStyleToTmbImgs();
  if (getDispTypeByWGTT() === "block") {
    document.getElementById("btnSmYearClose").style.display = "";
  }
  document.getElementById("btnSmClose").style.display = "";

  if (!wrpTmbs) {
    shield.style.display = "none";
    // overlay.style.display = "none";
    iconLoader.style.display = "none";
    overlay.style.zIndex = ZINDEX.OVERLAY;
  }
}

export async function onClickDecideBike(event) {
  const wrpFullImg = document.getElementById("wrpFullImg");

  const mkr1 = wrpFullImg.dataset.mkr;
  const mdl1 = wrpFullImg.dataset.mdl;
  const years1 = wrpFullImg.dataset.years;
  // const year1 = wrpFullImg.dataset.year;
  const color1 = wrpFullImg.dataset.color;
  const wrpImg = document.querySelector(
    `div[data-class='tmbColor'][data-mkr='${mkr1}'][data-mdl='${mdl1}'][data-years='${years1}'][data-color='${color1}'] div[data-class='wrpImg']`
  );
  if (!wrpImg) return;

  // compare状態ならsizeへ
  const isCompare = document.getElementById("display").dataset.compareDisp;
  if (isCompare === "true") {
    document.getElementById("btnCompare").click();
  }

  // 削除しないと描画済みのバイクが増えて処理が重くなる
  removeBikes();
  removeSizeTable();
  removeSizePagination();

  initBikes();
  initSizePagination();
  initSummaryValue();
  initBikeName();
  initImgDisp();
  initCompareToSizeTable();
  initSizeTable();

  duplicateImgDisp(wrpImg);

  const shield = document.getElementById("shield");
  const iconLoader = document.getElementById("iconLoader");
  const overlay = document.getElementById("overlay");

  // 取得した要素のinfoから各種処理
  shield.style.display = "";
  iconLoader.style.display = "";
  overlay.style.display = "";
  document.getElementById("sideMenu").style.display = "none";
  document.getElementById("btnShowSideMenu").style.zIndex = "";
  // const yearH = wrpImg.parentElement.parentElement.previousElementSibling;
  // setInfoToDisp(yearH.dataset.year, "year");

  // const info = getSelectedInfoFromSMMM();
  const mkrMdlYear = `${mkr1}_${mdl1}_${years1}`;
  const values = await execGetBikeData("geometry", mkrMdlYear);
  if (!values) {
    alert("Unable to obtain information.");
    shield.style.display = "none";
    iconLoader.style.display = "none";
    overlay.style.display = "none";
    return;
  }

  // sizeTable wrpBike positionSizeで列番号を揃えるためあらかじめ共通で使用する変数にセットしておく
  const colCount = COL_COUNT.get();
  for (let i = 0; i < values.length; i++) {
    values[i].info.colIndex = colCount + i;
  }
  COL_COUNT.set(values.length);

  // process
  drawBikes(values);
  setBikeDataToSizeTable("size", wrpImg, values);
  createSizePagination(values);
  setSizePagination();
  setWordingToThNameHeader(mkr1, mdl1, years1);
  setBikeNameToThNameHeader();
  setSizeTableBy("size", mkr1, mdl1, years1);
  setStyleToTDTH();
  setDrawColorToCompareTag();
  selectFirstSize(colCount);
  document.getElementById("shield").style.display = "none";
  document.getElementById("overlay").style.display = "none";
  document.getElementById("iconLoader").style.display = "none";

  // document.getElementById("smMakerModel").style.display = "none";
  // document.getElementById("smYear").style.display = "none";
  // document.getElementById("btnSmYearClose").style.display = "none";

  // doProcessClickSelectModel();
  // async function doProcessClickSelectModel() {

  // }
  function selectFirstSize(colCount) {
    const t = document.querySelector(
      `button[data-class='positionSize'][data-col-index='${colCount}']`
    );
    if (!t) return;
    t.click();
  }
}

export function onClickImgOfColors(event) {
  const tmbColor = getEventTargetsByBetween(event).find(
    (t) => t.dataset.class === "tmbColor"
  );
  if (!tmbColor) return;
  showFullImgByBike(tmbColor);
}

export function initModelUlToSmYear() {
  document.querySelectorAll("li[data-class='listYearModel']").forEach((t) => {
    t.style.display = "none";
  });
}

export function setModelUlToSmYear(target) {
  document.querySelector(
    `li[data-class='listYearModel'][data-mdl='${target.dataset.mdl}']`
  ).style.display = "";
}

export function getSelectedListOfModelBySmMakerModel() {
  const mdl = document.getElementById("smMakerModel").dataset.mdl;
  return document.querySelector(
    `li[data-class='listMakerModel'][data-mdl='${mdl}']`
  );
}

export function getSelectedInfoFromSMMM() {
  const smMM = document.getElementById("smMakerModel");
  const mkr = smMM.dataset.mkr;
  const mdl = smMM.dataset.mdl;
  const years = smMM.dataset.years;
  const size = smMM.dataset.size;
  return { mkr, mdl, years, size };
}

export const setWordingToYearH = (t) => {
  const ts = document.querySelectorAll(`h3[data-class='yearH']`);
  ts.forEach((t) => {
    const mkr = getWordingNameByAll(t.dataset.mkr);
    const mdl = getWordingNameByAll(t.dataset.mdl);
    const years = getYears(t.dataset.years);
    if (!mkr || !mdl) return;
    t.dataset.wordingEng = `${mkr.eng}  ${mdl.eng}  ${years}`;
    t.dataset.wordingJpn = `${mkr.jpn}  ${mdl.jpn}  ${years}`;
  });
};

export const setBikeNameToYearH = () => {
  const lang = document.getElementById("display").dataset.lang;
  const map = { eng: "wordingEng", jpn: "wordingJpn" };
  const ts = document.querySelectorAll(`h3[data-class='yearH']`);
  ts.forEach((t) => {
    t.textContent = t.dataset[map[lang]];
  });
};
