"use strict";
import { getYears, getYearsByArray, replaceName } from "./_common";
import { NAMES } from "./_const";
import {
  getWordingAfterBy,
  getWordingName,
  getWordingNameByAll,
} from "./wording";

export const setSimilar = (sizes) => {
  const diffLen = 10;
  const diffAgl = 2;
  // 違う場合は処理を飛ばす
  const aryModels = [];
  const arySizes = [];
  for (let i = 0; i < sizes.length; i++) {
    // ary.push([sizes[i].id]);
    const sizes1 = sizes[i];
    for (let j = 0; j < sizes.length; j++) {
      const sizes2 = sizes[j];
      if (i === j) continue;
      if (diffLen < Math.abs(sizes1.stCtoT - sizes2.stCtoT)) continue;
      if (diffLen < Math.abs(sizes1.stCtoC - sizes2.stCtoC)) continue;
      if (diffLen < Math.abs(sizes1.ttEfc - sizes2.ttEfc)) continue;
      if (diffLen < Math.abs(sizes1.ttLen - sizes2.ttLen)) continue;
      if (diffLen < Math.abs(sizes1.htLen - sizes2.htLen)) continue;
      if (diffLen < Math.abs(sizes1.fRake - sizes2.fRake)) continue;
      if (diffLen < Math.abs(sizes1.csLen - sizes2.csLen)) continue;
      if (diffLen < Math.abs(sizes1.bbDro - sizes2.bbDro)) continue;
      if (diffLen < Math.abs(sizes1.fLen - sizes2.fLen)) continue;
      if (diffAgl < Math.abs(sizes1.htAgl - sizes2.htAgl)) continue;
      if (diffAgl < Math.abs(sizes1.stAgl - sizes2.stAgl)) continue;

      const names1 = getNames(sizes1);
      const names2 = getNames(sizes2);
      aryModels.push(`${names1.mdl},${names2.mdl}`);
      arySizes.push(`${names1.size},${names2.size}`);
    }
  }

  arySizes.forEach((bikes) => {
    const bike = bikes.split(",");

    const name1 = bike[0].split("_");
    const mkr1 = name1[0];
    const mdl1 = name1[1];
    // const year1 = name1[2];
    const year1 = name1[2];
    const size1 = name1[3];

    const name2 = bike[1].split("_");
    const mkr2 = name2[0];
    const mdl2 = name2[1];
    const year2 = name2[2];
    const size2 = name2[3];
    // console.log(bike);
    // console.log(bike);
    const h3 = document.querySelector(
      `h3[data-class='yearH'][data-mkr='${mkr1}'][data-mdl='${mdl1}'][data-years='${year1}']`
    );
    const text = `${size1}:${bike[1]}`;
    if (h3.dataset.similar1 === "") {
      h3.dataset.similar1 = text;
    } else {
      h3.dataset.similar1 = `${h3.dataset.similar1},${text}`;
    }
  });

  const aryModels2 = [...new Set(aryModels)];
  aryModels2.forEach((bikes) => {
    const bike = bikes.split(",");

    const name1 = bike[0].split("_");
    const mkr1 = name1[0];
    const mdl1 = name1[1];
    const year1 = name1[2];

    const name2 = bike[1].split("_");
    const mkr2 = name2[0];
    const mdl2 = name2[1];
    const year2 = getYears(name2[2]);
    const h3 = document.querySelector(
      `h3[data-class='yearH'][data-mkr='${mkr1}'][data-mdl='${mdl1}'][data-years='${year1}']`
    );
    const text = `${mkr2}_${mdl2}_${year2}`;
    if (h3.dataset.similar2 === "") {
      h3.dataset.similar2 = text;
    } else {
      h3.dataset.similar2 = `${h3.dataset.similar2},${text}`;
    }
  });

  function getNames(sizes) {
    const temp = sizes.id.split("_");
    const mkr = temp[0];
    const mdl = temp[1];
    const year = getYears(temp[2]);
    const size = temp[3];
    return {
      mdl: `${mkr}_${mdl}_${year}`,
      size: `${mkr}_${mdl}_${year}_${size}`,
    };
  }
};

export const getSimilar = (mkr, mdl, year, size0) => {
  const size = String(size0); // サイズが数値の場合がある
  const h3 = document.querySelector(
    `h3[data-class='yearH'][data-mkr='${mkr}'][data-mdl='${mdl}'][data-years='${year}']`
  );
  if (h3.dataset.similar1 === "") return "";

  const ary = [];
  const similars = h3.dataset.similar1.split(",");
  for (let i = 0; i < similars.length; i++) {
    const bikes = similars[i].split(":");
    const size1 = bikes[0];
    if (size !== size1) continue;
    ary.push(bikes[1]);
  }
  return ary;
};

export const setBikeNameToSimilarOfSideMenu = () => {
  // set
  const yearHs = document.querySelectorAll(`h3[data-class='yearH']`);
  for (let i = 0; i < yearHs.length; i++) {
    const similar1 = yearHs[i].dataset.similar2;
    if (!similar1) continue;
    const similarText = getSimilarBikes(similar1);
    yearHs[i].parentElement.querySelector(
      "p[data-class='similarP']"
    ).innerText = similarText;
  }
};

export const getSimilarBikesToContentBox = (thImg) => {
  const btn = document.querySelector(
    `td[data-class='similar'][data-col-index='${thImg.dataset.colIndex}'] button`
  );
  return getSimilarBikes(btn.dataset.similar3);
};

const getSimilarBikes = (similar) => {
  const similarText = getWordingAfterBy(
    document.getElementById("contentBox"),
    "cbSimilar"
  );
  const ary = [similarText];
  const bikes = similar.split(",");
  bikes.forEach((bike) => {
    const names = bike.split("_");
    const mkr = getWordingName(names[0]);
    const mdl = getWordingName(names[1]);
    const years = getYears(names[2]);
    const size = names[3];
    const text = `${mkr}  ${mdl}  ${years}`;
    if (size) {
      ary.push(`${text}  ${size}`);
    } else {
      ary.push(text);
    }
  });
  const ary2 = ary.join("\n");
  return ary2;
};
